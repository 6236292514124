.container {
  width: 100vw;
  height: 100vh;
  background: #253746;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

body {
  margin: 0px;
}
