// /* General */
$fontSize: 14px;
$borderRadius: 12px;
$transitionDuration: 0.2s;
// $bodyBgColor: #edf0f5;
// $textColor: #333333;
// $textSecondaryColor: #707070;
// $dividerColor: #e2d1d1;
// $maskBgColor: #424242;
// $focusShadowColor: #e3e1ec00;

// /* Menu Common */
// $menuitemBadgeBgColor: #2e3a48;
// $menuitemBadgeColor: #ffffff;
// $submenuFontSize: 13px;
// $menuitemActiveRouteColor: #2e3a48; // Cor que a palavra fica quando selecionada

// /* Menu Light */
// $menuBgColorFirst: #ffffff; //Cor de fundo em cima do menu
// $menuBgColorLast: #ffffff; // Cor de fundo de baixo do menu
// // $menuBgColorFirst:#e3e1ec; //Cor de fundo em cima do menu
// // $menuBgColorLast:#e3e1ec; // Cor de fundo de baixo do menu
// $menuitemColor: #253746; // Cor das palavras que não estão selecionadas
// $menuitemHoverColor: #c91143; // Cor quando passa o mouse em cima das palavras do menu
// $menuitemActiveColor: #253746; //Cor que o campo que abre mais páginas fica quando aberta
// $menuitemActiveBgColor: #eeeeee; // Cor de fundo do submenu
// $menuitemBorderColor: #d2cfdd;

// /* Menu Dark */
// $menuDarkBgColorFirst: #4d505b;
// $menuDarkBgColorLast: #3b3e47;
// $menuitemDarkColor: #ffffff;
// $menuitemDarkHoverColor: #0388e5;
// $menuitemDarkActiveColor: #0388e5;
// $menuitemDarkActiveBgColor: #2e3035;
// $menuitemDarkBorderColor: rgba(52, 56, 65, 0.6);

/* Topbar */
$topbarBgColor: #2e3a48;
$topbarBgColorDev: #aa1f03;
$topbarBgColorBeta: #007506;

// $topbarItemBadgeBgColor: #ef6262;
// $topbarItemBadgeColor: #ffffff;
// $topbarItemColor: #ffffff;
// $topbarItemHoverColor: #c3e8fb;
// $topbarSearchInputBorderBottomColor: #ffffff;
// $topbarSearchInputColor: #ffffff;

// /* Footer */
// $footerBgColor: #ffffff;
