@import './_variables';
@import './sass/_layout';
@import './_overrides';

body {
  overflow: overlay !important;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border: solid 3px transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
}
